import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

export const reactPlugin = new ReactPlugin();
export const appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.REACT_APP_AI_CONNECTION_STRING,
    disableAjaxTracking: true,
    disableFetchTracking: true,
    disableTelemetry: process.env.NODE_ENV !== 'production',
    enableAutoRouteTracking: true,
    extensions: [reactPlugin]
  }
});
