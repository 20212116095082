import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { RouteSuspense } from './routes/RouteSuspense';
import { AppProvider } from './utils/state';
const PrivateRoutes = React.lazy(() => import('./routes/PrivateRoutes'));
const PublicRoutes = React.lazy(() => import('./routes/PublicRoutes'));

function App() {
  return (
    <AppProvider>
      <Routes>
        <Route
          path="/*"
          element={
            <RouteSuspense>
              <PrivateRoutes />
            </RouteSuspense>
          }
        />
        <Route
          path="/external/*"
          element={
            <RouteSuspense>
              <PublicRoutes />
            </RouteSuspense>
          }
        />
      </Routes>
    </AppProvider>
  );
}

export default App;
