import { createTheme } from '@mui/material';
import { LinkProps } from '@mui/material/Link';
import { LinkBehavior } from './LinkBehaviour';
// this import is required for datagrid theming
import type {} from '@mui/x-data-grid/themeAugmentation';
// this import is required for date picker theming
import type {} from '@mui/x-date-pickers/themeAugmentation';

const baseTheme = createTheme({
  palette: {
    primary: {
      main: '#162859',
      light: '#1628591a',
      dark: '#0A1638',
      background: '#F1F5F5' //light1
    },
    secondary: {
      main: '#1A50DD',
      light: '#C9F2F2',
      dark: '#0C276C',
      background: '#E8EEEE' //light2
    },
    info: {
      main: '#51B0AC',
      light: '#E7EEEE',
      dark: '#146360',
      background: '#DEE9E8' //light3
    },
    success: {
      main: '#538742',
      light: '#53874233',
      dark: '#2A5B1A',
      background: '#323030' //dark1
    },
    warning: {
      main: '#E1B92B',
      light: '#FDEBBE',
      dark: '#8F7518',
      background: '#454545' //dark2
    },
    error: {
      main: '#9F372F',
      light: '#F9DDDB',
      dark: '#67150E'
    }
  },
  typography: {
    fontFamily: 'Inter'
  },
  custom: {
    palette: {
      grey: '#EAECF0',
      dark2: '#454545',
      white: '#FFFFFF',
      infoLightDarkened: '#CBD1D1',
      grey300: '#D0D5DD',
      grey600: '#475467',
      greenGrey: '#586463',
      lightGrey: '#A1A5A5',
      blueGrey: '#F2F4F7',
      infoLight50: '#E7EEEE80',
      activeBack: '#DDE7D9',
      activeFore: '#2A5B19',
      progressBack: '#D9E2E2',
      dueSoonFore: '#5C543E',
      light1: '#F1F5F5',
      light2: '#E8EEEE',
      light3: '#DEE9E8',
      accentBackground: '#C6D7D5',
      darkGrey: '#313636',
      disabledBackgroundColor: '#16285926',
      disabledColor: '#16285973'
    }
  }
});

// Create a theme instance.
const theme = createTheme(baseTheme, {
  typography: {
    fontFamily: 'Inter',
    subtitle3: {
      color: baseTheme.custom.palette.greenGrey,
      fontSize: '12px',
      fontFamily: 'Inter',
      fontWeight: '400',
      lineHeight: '16px'
    }
  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          textTransform: 'uppercase',
          fontSize: '12px',
          fontWeight: '500',
          '& .MuiChip-label': {
            padding: '2px 4px'
          }
        }
      },
      variants: [
        {
          props: { variant: 'assigned' },
          style: {
            backgroundColor: baseTheme.palette.secondary.main,
            color: baseTheme.custom.palette.white
          }
        },
        {
          props: { variant: 'active' },
          style: {
            backgroundColor: baseTheme.custom.palette.activeBack,
            color: baseTheme.custom.palette.activeFore
          }
        },
        {
          props: { variant: 'inProgress' },
          style: {
            backgroundColor: baseTheme.custom.palette.progressBack,
            color: baseTheme.custom.palette.greenGrey
          }
        },
        {
          props: { variant: 'dueSoon' },
          style: {
            backgroundColor: baseTheme.palette.warning.light,
            color: baseTheme.custom.palette.greenGrey
          }
        },
        {
          props: { variant: 'overdue' },
          style: {
            backgroundColor: baseTheme.palette.error.light,
            color: baseTheme.palette.error.dark
          }
        }
      ]
    },
    MuiAutoComplete: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          fontWeight: '400',
          '&:hover': {
            backgroundColor: `${baseTheme.palette.secondary.light}`
          },
          '&:focus': {
            backgroundColor: `${baseTheme.palette.info.light}`
          }
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          margin: '40px',
          border: 'none',
          '& .MuiDataGrid-main': {
            color: `${baseTheme.custom.palette.white}`,
            width: '100%'
          },
          '& .MuiDataGrid-virtualScroller': {
            border: '1px solid #E0E0E0',
            borderTop: 'none',
            borderRadius: '0 0 4px 4px'
          },
          '& .MuiDataGrid-virtualScrollerRenderZone': {
            '& .MuiDataGrid-row': {
              backgroundColor: `${baseTheme.custom.palette.white}`,
              fontSize: '14px',
              '&:nth-of-type(even)': { backgroundColor: '#F1F5F5' }
            }
          },
          '& .MuiDataGrid-columnHeader': {
            backgroundColor: '#3E4545',
            color: `${baseTheme.custom.palette.white}`,
            textTransform: 'uppercase',
            fontWeight: '500',
            fontSize: '12px',
            padding: '0 0 0 24px'
          },
          '& .MuiDataGrid-menuIcon': {
            display: 'none',
            '&:hover': {
              display: 'none'
            }
          },
          '& .MuiDataGrid-cell': {
            color: '#292727',
            padding: '0 0 0 24px',
            '&:focus-within': {
              outline: 'none'
            }
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none'
          },
          '& .MuiDataGrid-iconSeparator': {
            visibility: 'hidden'
          },
          '& .MuiTablePagination-actions': {
            '& .MuiButtonBase-root': {
              color: '#313636',
              'svg:hover': {
                color: '#313636'
              }
            },
            '& .Mui-disabled': {
              color: '#31363680'
            }
          },
          '& .MuiSvgIcon-root': {
            opacity: '1 !important'
          },
          '& .MuiIconButton-root': {
            color: `${baseTheme.custom.palette.white}`,
            '& :hover': {
              color: `${baseTheme.palette.secondary.light}`
            },
            '& :focus': {
              color: `${baseTheme.palette.secondary.light}`
            }
          }
        }
      }
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            fontWeight: '600',
            fontFamily: 'Inter',
            color: '#fff',
            backgroundColor: 'primary',
            borderRadius: '100px',
            fontSize: '12px',
            lineHeight: '20px',
            padding: '10px 24px',
            gap: '8px',
            '&:hover': {
              opacity: '0.8'
            },
            '&:focus': {
              opacity: '0.8'
            },
            '&:visited': {
              opacity: '0.8'
            },
            '&:disabled': {
              backgroundColor: '#16285926',
              color: '#16285973'
            }
          }
        },
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            fontWeight: '600',
            fontFamily: 'Inter',
            color: 'primary',
            backgroundColor: '#fff',
            border: '#16285999 solid 1px',
            borderRadius: '100px',
            fontSize: '12px',
            lineHeight: '20px',
            padding: '10px 24px',
            gap: '8px',
            '&:hover': {
              backgroundColor: '#16285914'
            },
            '&:focus': {
              backgroundColor: '#16285914'
            },
            '&:visited': {
              backgroundColor: '#16285914'
            },
            '&:disabled': {
              color: '#16285961',
              border: '#16285961 1px solid'
            }
          }
        },
        {
          props: { variant: 'text', color: 'primary' },
          style: {
            fontWeight: '600',
            fontFamily: 'Inter',
            color: 'primary',
            backgroundColor: '#fff',
            borderRadius: '100px',
            fontSize: '12px',
            lineHeight: '20px',
            padding: '10px 12px',
            gap: '8px',
            '&:hover': {
              backgroundColor: '#16285914'
            },
            '&:focus': {
              backgroundColor: '#16285914'
            },
            '&:visited': {
              backgroundColor: '#16285914'
            },
            '&:disabled': {
              color: '#16285961'
            }
          }
        }
      ]
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: '#292727',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'none'
          },
          '& fieldset': {
            borderColor: baseTheme.custom.palette.lightGrey
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: baseTheme.palette.secondary.main
          },
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: '#1c1b1f1f'
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: baseTheme.palette.secondary.main
          },
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: baseTheme.palette.error.main
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          paddingTop: 0,
          color: baseTheme.custom.palette.greenGrey,
          '&.Mui-focused': {
            color: baseTheme.palette.secondary.main,
            textTransform: 'uppercase'
          },
          '&.Mui-error.Mui-focused': {
            color: baseTheme.palette.error.main
          },
          '&.Mui-disabled': {
            color: '#26252838'
          },
          '&.MuiInputLabel-shrink': {
            textTransform: 'uppercase'
          }
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '12px',
          color: `${baseTheme.custom.palette.greenGrey}`,
          marginLeft: '0',
          fontWeight: '400',
          '&.Mui-focused': {
            color: baseTheme.palette.secondary.main
          },
          '&.Mui-error.Mui-focused': {
            color: baseTheme.palette.error.main
          },
          '& .Mui-error': {
            color: baseTheme.palette.error.main
          }
        }
      }
    },
    MuiBadge: {
      variants: [
        {
          props: { variant: 'main' },
          style: {
            '.MuiBadge-badge': {
              borderColor: '#FFFFFF',
              borderWidth: '1px',
              borderStyle: 'solid',
              color: '#FFFFFF',
              paddingLeft: '4px',
              paddingRight: '4px',
              minWidth: '15px',
              height: '15px',
              fontSize: '8px'
            },
            '.MuiBadge-anchorOriginTopRight': {
              right: '3px',
              top: '5px'
            }
          }
        }
      ]
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior
      } as LinkProps,
      variants: [
        {
          props: { component: 'button' },
          style: {
            color: baseTheme.custom.palette.greenGrey
          }
        }
      ]
    },
    MuiSnackbar: {
      defaultProps: {
        anchorOrigin: { vertical: 'top', horizontal: 'right' }
      },
      variants: [
        {
          props: { variant: 'error' },
          style: {
            opacity: 0.8,
            '.MuiSnackbarContent-root': {
              backgroundColor: baseTheme.palette.error.dark,
              borderRadius: '6px'
            }
          }
        },
        {
          props: { variant: 'message' },
          style: {
            opacity: 0.8,
            '.MuiSnackbarContent-root': {
              backgroundColor: baseTheme.custom.palette.dark2,
              borderRadius: '6px'
            }
          }
        },
        {
          props: { variant: 'success' },
          style: {
            opacity: 0.8,
            '.MuiSnackbarContent-root': {
              backgroundColor: '#015B50',
              borderRadius: '6px'
            }
          }
        }
      ]
    },
    MuiLinearProgress: {
      defaultProps: {
        color: 'secondary'
      },
      styleOverrides: {
        root: { height: 8 }
      }
    },
    MuiCircularProgress: {
      defaultProps: {
        color: 'secondary'
      }
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'secondary'
      }
    }
  }
});

theme.typography.h1 = {
  fontSize: '36px',
  fontFamily: 'Inter',
  fontWeight: '300',
  lineHeight: '44px'
};
theme.typography.h2 = {
  fontSize: '28px',
  fontFamily: 'Inter',
  fontWeight: '300',
  lineHeight: '36px'
};
theme.typography.h3 = {
  fontSize: '24px',
  fontFamily: 'Inter',
  fontWeight: '300',
  lineHeight: '32px'
};
theme.typography.h4 = {
  fontSize: '22px',
  fontFamily: 'Inter',
  fontWeight: '300',
  lineHeight: '28px'
};
theme.typography.h5 = {
  fontSize: '16px',
  fontFamily: 'Inter',
  fontWeight: '500',
  lineHeight: '24px'
};
theme.typography.h6 = {
  fontSize: '14px',
  fontFamily: 'Inter',
  fontWeight: '500',
  textTransform: 'uppercase',
  lineHeight: '20px'
};
theme.typography.caption = {
  // small label
  fontSize: '12px',
  fontFamily: 'Inter',
  fontWeight: '500',
  textTransform: 'uppercase',
  lineHeight: '16px'
};
theme.typography.subtitle1 = {
  //body large
  fontSize: '16px',
  fontFamily: 'Inter',
  fontWeight: '400',
  lineHeight: '24px'
};
theme.typography.body1 = {
  //body med
  fontSize: '14px',
  fontFamily: 'Inter',
  fontWeight: '400',
  lineHeight: '20px'
};
theme.typography.body2 = {
  //body small
  fontSize: '12px',
  fontFamily: 'Inter',
  fontWeight: '400',
  lineHeight: '16px'
};

export default theme;
