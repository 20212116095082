import React from 'react';
import ReactDOM from 'react-dom/client';
import { CssBaseline, ThemeProvider } from '@mui/material';

import App from './App';
import theme from './styles/theme';
import { QueryClient, QueryClientProvider } from 'react-query';
//import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { appInsights, reactPlugin } from './libs/telemetry/appInsights';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';

function assertIsNonNullish<T>(value: T, message: string): asserts value is NonNullable<T> {
  if (value === null || value === undefined) {
    throw Error(message);
  }
}

const queryClient = new QueryClient();
appInsights.loadAppInsights();

if (process.env.REACT_APP_HEAP_ID) {
  const heapNode: Element = document.createElement('script');
  heapNode.innerHTML = `window.heap=window.heap||[],
    heap.load=function(e,t){
        window.heap.appid=e,
        window.heap.config=t=t||{};
        var r=document.createElement("script");
        r.type="text/javascript",
        r.async=!0,
        r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";
        var a=document.getElementsByTagName("script")[0];
        a.parentNode.insertBefore(r,a);
        for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=[
          "addEventProperties",
          "addUserProperties",
          "clearEventProperties",
          "identify",
          "resetIdentity",
          "removeEventProperty",
          "setEventProperties",
          "track",
          "unsetEventProperty"
        ],o=0;o<p.length;o++)heap[p[o]]=n(p[o])
      };
    heap.load("${process.env.REACT_APP_HEAP_ID}");`;
  document.head.appendChild(heapNode);
}

const rootElement = document.getElementById('root'); // rootElement of type HTMLElement | null
assertIsNonNullish(rootElement, `Couldn't find DOM element #root`);

const appRoot = ReactDOM.createRoot(rootElement); // here rootElement should have the type HTMLElement only

appRoot.render(
  <React.StrictMode>
    <AppInsightsContext.Provider value={reactPlugin}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient} contextSharing={true}>
          <BrowserRouter>
            {/*<ReactQueryDevtools />*/}
            <CssBaseline />
            <App />
          </BrowserRouter>
        </QueryClientProvider>
      </ThemeProvider>
    </AppInsightsContext.Provider>
  </React.StrictMode>
);
