import { Dispatch, SetStateAction, createContext, useContext, useState } from 'react';

interface StateContextType {
  state: any;
  setState: Dispatch<SetStateAction<any>>;
}

export const AppStateContext = createContext<StateContextType>({ state: {}, setState: () => {} });

export const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, setState] = useState({});
  const value = { state, setState };
  return <AppStateContext.Provider value={value}>{children}</AppStateContext.Provider>;
};

export const useAppState = () => {
  const context = useContext(AppStateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppProvider');
  }
  return context;
};
